<template>
  <div class="mt-4">
    <div class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full">
      <div class="flex justify-center items-center">
        <h1 class="heading-2 text-center">Item Detail</h1>
        <div class="absolute right-7 flex items-center gap-3">
          <div class=" text-error heading-4 cursor-pointer bg-white px-3 py-1 rounded-xl" @click="removeConfirm = true">
            <i class="fa-solid fa-trash"></i>
          </div>
          <div class=" text-primary heading-4 cursor-pointer bg-white px-2.5 py-1 rounded-xl" @click="editItems()">
            <i class="fa-solid fa-pen-to-square"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="" v-if="itemInfo !== null">
      <div class=" card rounded-xl bg-white p-4 my-4 sm:w-full lg:w-10/12 xl:w-8/12 2xl:w-6/12">
        <div class="relative">
      <div>
        <div class="p-4">
          <p class="text-text2 heading-2 font-bold">{{itemInfo.itemName}}</p>
          <p class="text-gray4 mb-4">{{itemInfo.itemCode}}</p>
          <div class=" text-gray3 mb-4 grid grid-cols-12">
            <div class="col-span-6">
              <p>Item Price</p>
              <p class="text-text2">{{itemInfo.itemPrice | amountOnlyNumber}}</p>
            </div>
            <div class="col-span-6">
              <p>Unit</p>
              <p class="text-text2" v-if="itemInfo.itemUnit !== ''">{{itemInfo.itemUnit}}</p>
              <p class="text-text2" v-else>-</p>
            </div>
          </div>
          <div class="flex text-gray3 mb-4">
            <div>
              <p>Category</p>
              <p class="text-text2" v-if="itemInfo.categoryName !== ''">{{itemInfo.categoryName}}</p>
              <p class="text-text2" v-else>-</p>
            </div>
          </div>
          <div class="flex text-gray3 mb-4" v-if="itemInfo.itemDescription !== ''">
            <div>
              <p>Item Description</p>
              <p class="text-text2" >{{itemInfo.itemDescription}}</p>
            </div>
          </div>
          <div v-if="itemInfo.itemTaxes !== null">
            <div class="divider my-2"></div>
              <p class="text-text2 font-bold">Tax:</p>
              <div>
                <div v-for="(taxes, index) in itemInfo.itemTaxes" :key="index" class="flex border-b py-2 heading-6">
                  <div class="w-full">
                      <div class="flex justify-between">
                        <div class="text-gray4">{{taxes.taxName.substr(0, 20)}} <span v-if="taxes.taxName.length > 20">...</span></div>
                        <div>
                          <p class="text-gray4 text-right">({{taxes.taxValue}} %)</p>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
        </div>
      </div>
      </div>
      </div>
    </div>
    <ConfirmBox v-if="removeConfirm" :message="'Once deleted cannot undone.'" :title="'Are you sure?'" />
  </div>
</template>
<script>
import ConfirmBox from '@/View/components/ConfirmBox.vue'
import MyJobApp from '@/api/MyJobApp.js'
export default {
components: {
  ConfirmBox
},
data () {
  return {
    itemInfo: null,
    removeConfirm: false,
    finalTaxList: [],
    taxesAmmount: 0
  }
},
created() {
},
mounted () {
  this.$root.$on('confirmBoxHandler', (response) => {
    if (response) {
      this.deleteItemApi()
    }
    document.body.style = 'overflow: visible;'
    this.removeConfirm = false
  })
  this.getItemDetails()
  document.body.style = 'overflow: hidden;'
},
watch: {},
methods: {
  editItems () {
    this.$router.push({name: 'SettingItemEdit', params: {itemId: parseInt(this.$route.params.itemId)}})
  },
  getItemDetails () {
    MyJobApp.getSettingItemsDetail(
      parseInt(this.$route.params.itemId),
        response => {
          this.itemInfo = response.Data !== null ? response.Data : null
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
  deleteItemApi () {
    MyJobApp.settingItemDeleteApi(
      parseInt(this.$route.params.itemId),
        response => {
          this.$router.push({name: 'Items', query: {activeTab: 0}})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})

        },
        () => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
},
beforeDestroy () {
  this.$root.$off('confirmBoxHandler')
  // this.$root.$off('closeProfileDialog')
}
}
</script>
<style scoped>

</style>